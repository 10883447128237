<template>
  <v-container fluid>
    <v-card v-if="user" tile>
      <v-card-title>Profile</v-card-title>

      <v-card-text>
        <v-form @submit.prevent="updateUserProfile">
          <v-text-field v-model="user.id" readonly label="UID"></v-text-field>
          <v-text-field v-model="user.name" readonly label="Name"></v-text-field>
          <v-text-field v-model="user.surname" readonly label="Surname"></v-text-field>
          <v-text-field v-model="user.username" readonly label="Username"></v-text-field>
          <v-text-field v-model="user.email" label="E-mail" type="email"></v-text-field>
          <v-text-field v-model="user.roomNumber" label="Room number"></v-text-field>
          <v-text-field v-model="user.dateOfBirth" readonly label="Date of birth"></v-text-field>
          <v-text-field v-model="user.placeOfBirth" readonly label="Place of birth"></v-text-field>
          <v-text-field v-model="user.permanentAddress" readonly label="Permanent address"></v-text-field>

          <v-card-actions>
            <v-btn type="submit" color="success">Save</v-btn>
            <v-btn color="warning" @click="changePasswordDialog = true">Change password</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="changePasswordDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >

      <v-card>
        <v-card-title>
          <span class="headline">Change password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :rules="[rules.required, rules.min]"
                  label="New password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="changePasswordDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="updateUserPassword">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      user: null,
      password: null,
      showPassword: false,
      changePasswordDialog: false,
      isValid: false,
      rules: {
        required: value => !!value || 'Required field',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  methods: {
    async updateUserProfile () {
      await this.$store.dispatch('account/updateUserProfile', this.user)
    },
    async updateUserPassword () {
      this.changePasswordDialog = false
      await this.$store.dispatch('account/updateUserPassword', this.password)
    }
  },
  async mounted () {
    document.title = 'Profile | Masarka Student Club'
    this.user = await this.$store.dispatch('account/fetchUserProfile')
  }

}
</script>
